import React from "react";
import Loader from "./Elements/Loader";
import { connect } from "react-redux";
import TopMenu from "./Layout/TopMenu/TopMenu";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Layout/Login/Login";
import { getUserData,logOut} from "./../redux/actions";
import MenuList from "./Elements/MenuList";
import BottomMenu from "./Elements/BottomMenu";
import MembersIndividual from "./Pages/Register";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { Button, Drawer, notification,Tooltip, Badge, Avatar } from "antd";
import logo from "../assets/img/logo.svg";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SmileOutlined,
  FrownOutlined,
  BellFilled,
  HomeOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import history from "../const/history";
import Notfound from "./Elements/404";
import TypeOfActivity from "./Pages/Admin/TypeOfActivity/TypeOfActivity";
import Users from "./Pages/Admin/Users";
import Home from "./Layout/Home/Home";
import StatePrivate from "./Pages/Admin/StatePrivate";
import CreateModal from "./Pages/Register/create";
import ViewRegister from "./Pages/Register/view";
import Register from "./Pages/Register";
import Frame from '../assets/img/aictia.png'
import Dashboard from "./Pages/Dashboard";
import FieldOfUse from "./Pages/Admin/FieldOfUse"
import Types from "./Pages/Admin/Types";
import Technologies from "./Pages/Admin/Technologies";
import Sector from "./Pages/Admin/Sector";
import Logs from "./Pages/Admin/Logs";
import Position from "./Pages/Admin/Position";
import MyCabinet from "./Pages/MyCabinet";
import createOrganization from "./Pages/MyCabinet/createOrganization";
import ViewOrgazination from "./Pages/MyCabinet/view";
const { Content, Sider } = Layout;

class App extends React.Component {
  state = {
    collapsed: false,
    web: true,
    ismap: false,
    stocks: null,
    isWhite: true
  };

  toggleButtons = () => {
    const className = `flex sider-btn ${
      this.state.collapsed ? "all-center" : "flex-between open"
    }`;
    return (
      <div className={className}>
        {!this.state.collapsed ? (
          <>
            <div
            className="close-btn"
              onClick={() =>
                this.setState({
                  collapsed: true,
                })
              }
            >
              <CloseOutlined />
            </div>

          </>
        ) : null}

        {/* <Button type="primary" onClick={this.onCollapse}>
          {this.state.collapsed ? <MenuUnfoldOutlined /> : "ss"}
        </Button> */}

        {
          this.state.collapsed ? (
            <Button type="primary" onClick={this.onCollapse}>
               <MenuUnfoldOutlined />
          </Button>
          )
          :   <img src={Frame} alt="" />
        }

      </div>
    );
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.setState({ web: window.innerWidth > 1200 });
  };

  componentDidMount() {
    console.log(this.props);
    
    this.props.getUserData();
    this.setState({ web: window.innerWidth > 1200 });
    window.addEventListener("resize", () => {
      this.setState({
        web: window.innerWidth > 1200,
        // collapsed: window.innerWidth < 1200,
      });
    });
    this.setState({ ismap: window.location.pathname === "/" , isWhite: window.location.pathname === "/"  });
    history.listen((location) => {
      this.setState({ ismap: location.pathname === "/" , isWhite: location.pathname === "/" });
    });
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.notification;
    const curr = this.props.notification;
    console.log(curr);
    
    if (prev.notify !== curr.notify) {
      let desc = !curr.isHappy
        ? curr.description
          ? curr.description
          : this.props.t("errorMessage")
        : curr.description;
        console.log(desc);
        
      notification.info({
        message: curr.isHappy
          ? this.props.t("successMessage")
          : this.props.t("errMessage"),
        description: desc.length ? desc : null,
        icon: curr.isHappy ? <SmileOutlined /> : <FrownOutlined />,
      });
    }
  }

  
  render() {
    const { t } = this.props;
  console.log(this.props.isLoggedIn);
  const role = localStorage.getItem("role");
    return (
      <>
        {this.props.isLoading ? <Loader /> : null}
        {this.props.isLoggedIn ? (
          <>
            <div id="page">
              <Layout className="letside">
                {this.state.web ? (
                  <Sider
                    className="side-menu"
                    style={{ backgroundColor: "white" }}
                    collapsed={this.state.collapsed}
                    collapsedWidth={80}
                    onCollapse={this.onCollapse}
                    width={300}
                  >
                    {this.toggleButtons()}
                    <MenuList collapsed={this.state.collapsed} />
                    <BottomMenu />
                  </Sider>
                ) : (
                  <Drawer
                    className="drawer"
                    width={320}
                    title={this.toggleButtons()}
                    placement="left"
                    closable={false}
                    onClose={this.onCollapse}
                    visible={!this.state.collapsed}
                    key="key"
                  >
                    <MenuList collapsed={this.state.collapsed} />
                    <BottomMenu />
                  </Drawer>
                )}
                <Layout
                  className={
                    this.state.collapsed
                      ? "collapsedRight"
                      : "nonCollapsedRight"
                  }
                >
                 
                  <Content>
                    <div
                      className={`page-routes`}
                    >
                      <Switch>
                        <Route exact path={`/register`} component={Register} />
                        <Route exact path={`/organization`} component={MyCabinet} />
                        <Route exact path={`/activity`} component={TypeOfActivity} />
                        <Route exact path={`/admin/users`} component={Users} />
                        <Route exact path={`/admin/stateorprivate`} component={StatePrivate} />
                        <Route exact path={`/register/create`} component={CreateModal} />
                        <Route exact path={`/organization/create`} component={createOrganization} />
                        <Route exact path={`/organization/edit/:id`} component={createOrganization} />
                        <Route exact path={`/register/edit/:id`} component={CreateModal} />
                        <Route exact path={`/register/view/:id`} component={ViewRegister} />
                        <Route exact path={`/organization/view/:id`} component={ViewOrgazination} />

                        <Route exact path={`/dashboard`} component={Dashboard} />
                        <Route exact path={`/`} component={role=== 'Organization' ? MyCabinet :  Register} />
                        <Route exact path={`/admin/fieldofuse`} component={FieldOfUse} />
                        <Route exact path={`/admin/types`} component={Types} />
                        <Route exact path={`/admin/technologies`} component={Technologies} />
                        <Route exact path ={`/admin/sector`} component={Sector} />
                        <Route exact path ={`/admin/logs`} component={Logs} />
                        <Route exact path ={`/admin/position`} component={Position} />
                        <Redirect to="/" />
                        <Route path="/">
                          <div className='flex all-center h-100vh'>
                            <Notfound/>
                          </div>
                        </Route>
                      </Switch>
                    </div>
                  </Content>
                </Layout>
              </Layout>
            </div>
          </>
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/">
                <Notfound/>
              </Route>
            </Switch>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, loader, notification }) => {
  console.log(user);
  
  return {
    isLoggedIn: user.isLoggedIn,
    // isLoggedIn: true,
    isLoading: loader,
    user: user,
    notification,
  };
};

const exp = withTranslation()(App);
export default connect(mapStateToProps, { getUserData,logOut })(exp);
