import React, { useState, useEffect } from "react";

import { Row, Col, Card, Spin } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import Chart from "react-apexcharts";
import CountUp from "react-countup";
import { aictia } from "../../../const/api";

function Index() {
  const [data, setData] = useState([]);
  const [activityPie, setActivityPie] = useState({ labels: [], series: [] });
  const [companyPie, setCompanyPie] = useState({ labels: [], series: [] });
  const [activtyTypeSeries, setActivityTypeSeries] = useState([]);
  const [activityYears, setActivityYears] = useState([]);
  const [spin, setSpin] = useState(false);
  const getData = () => {
    setSpin(true);
    aictia.get("Dashboard").then((res) => {
      setSpin(false);
      setData(res.data);
      const activityFields = res.data?.dashboardData.find(
        (elem) => elem.name === "Fealliyyet Sahesi"
      );

      const companyType = res.data?.dashboardData.find(
        (elem) => elem.name === "Sahibkarliq subyekti"
      );
      const activityType = res.data?.dashboardData.find(
        (elem) => elem.name === "Fealiyyet Novu"
      );

      if (activityFields) {
        const activityLabels = activityFields?.data.map((elem) => elem.name);
        const activitySeries = activityFields?.data.map((elem) => elem.count);
        setActivityPie({ activityLabels, activitySeries });
      }

      if (companyType) {
        const companyLabels = companyType?.data.map((elem) => {
          switch (elem?.name) {
            case "1":
              return "MMC";
            case "2":
              return "QSC";
            case "3":
              return "ASC";
            case "4":
              return "Fiziki şəxs";
            case "5":
              return "İctimai Birlik";
            case "6":
              return "Digər";
            case "7":
              return "Filial";
            case "8":
              return "Nümayəndəlik";
          }
        });
        const companySeries = companyType?.data.map((elem) => elem.count);

        setCompanyPie({ companyLabels, companySeries });
      }

      if (activityType) {
        const allYears = [
          ...new Set(activityType.data.map((item) => item.year)),
        ];
      
        const yearsAfter2015 = allYears
          .filter((year) => parseInt(year) >= 2015)
          .reverse();
        const yearsBefore2015 = allYears
          .filter((year) => parseInt(year) < 2015);
      
        const names = [...new Set(activityType.data.map((item) => item.name))];
        const series = [];
      
        names.forEach((name) => {
          const dataCounts = [];
          let count = 0;
      
       
          yearsBefore2015.forEach((year) => {
            const yearData = activityType.data.filter(
              (item) => item.year === year && item.name === name
            );
            yearData.forEach((item) => (count += item.count));
          });
      
       
          yearsAfter2015.forEach((year) => {
            const yearData = activityType.data.filter(
              (item) => item.year === year && item.name === name
            );
            yearData.forEach((item) => (count += item.count));
            dataCounts.push(count);
          });
      
          series.push({
            name: name,
            data: dataCounts.reverse(),
          });
        });
      
    
        setActivityTypeSeries(series);
        setActivityYears(yearsAfter2015.reverse());
      }
      
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const series = activtyTypeSeries;

  const options = {
    chart: {
      type: "bar",
      height: 350,
      horizontalAlign: "left",

      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    // dataLabels: {
    //   enabled: false,
    // },
    xaxis: {
      type: "year",
      categories: activityYears || [],
    },

    legend: {
      position: "right",
      offsetY: 40,
    },
  };

  const activityField = {
    options: {
      labels: activityPie.activityLabels || [],
    },
    series: activityPie.activitySeries || [],
  };

  const typeOfCompany = {
    options: {
      labels: companyPie.companyLabels || [],
    },
    series: companyPie.companySeries || [],
  };
console.log(activtyTypeSeries);

  return (
    <div className="dashboard">
      <Col xs={24}>
        <div className="border animated fadeInDown p-2 mt-0 bg-white">
          <BarChartOutlined className="f-20 mr5-15" />
          <span className="f-20 bold">Hesabat</span>
        </div>
      </Col>
      {spin ? (
        <div className="flex flex-align-center justify-center mt-15">
          <Spin />
        </div>
      ) : (
        <>
          <Col className="chart-pie">
            <div className="flex flex-between">
              <div className="flex dir-column justify-center">
                <div className="company">
                  <p> Şirkətlərin ümumi sayı</p>

                  <CountUp end={data?.totalCount} separator=" " />
                </div>
                <div>
                  <h2 className="title">Fəaliyyət sahəsinə görə</h2>
                  <div className="mixed-chart">
                    <Chart
                      options={activityField.options}
                      series={activityField.series}
                      type="pie"
                      width="500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex dir-column  justify-center">
                <div className="company">
                  <p> Nizamnamə kapitalın cəmi</p>
                  <CountUp
                    end={data?.totalAutetotalAuthorizedCapital}
                    separator=" "
                  />
                </div>
                <h2 className="title"> Sahibkarlıq subyekti</h2>
                <div className="mixed-chart">
                  <Chart
                    options={typeOfCompany.options}
                    series={typeOfCompany.series}
                    type="pie"
                    width="500"
                  />
                </div>
              </div>
            </div>
          </Col>

          <div className="row">
            <div className=" chart-div">
              <div className="chart">
                <h2 className="title">Fəaliyyət növü</h2>
                {/* <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
              width={800}
            /> */}
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Index;
