import React, { useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Button,
  DatePicker,
  Form,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { noWhitespace, whiteSpace } from "../../../utils/rules";
import { notify } from "../../../redux/actions";
import aictia from "../../../const/api";
import moment from "moment";
import { connect } from "react-redux";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import history from "../../../const/history";
import { UsergroupAddOutlined } from "@ant-design/icons";
const { Option } = Select;

const Company = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const editor = useRef();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState([]);
  const [packet, setPacket] = useState([]);
  const [activity, setActivity] = useState([]);
  const [phoneNum, setPhoneNum] = useState(1);
  const [email, setEmail] = useState(1);
  const [ceoPhoneNum, setCeoPhoneNum] = useState(1);
  const [ceoEmail, setCeoEmail] = useState(1);
  const [text, setText] = useState({});
  const [data, setData] = useState([]);
  //   const { id } = useParams()
  const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
    { item: 4, code: "Fiziki şəxs" },
    { item: 5, code: "İctimai Birlik" },
    { item: 6, code: "Digər" },
    { item: 7, code: "Filial" },
    { item: 8, code: "Nümayəndəlik" },
  ];
  const socialMedia = [
    { title: "Facebook", name: "social1" },
    { title: "Twitter", name: "social2" },
    { title: "Linkedin", name: "social3" },
    { title: "Youtube", name: "social4" },
    { title: "Wikipedia", name: "social5" },
  ];
  const socialMediaCeo = [
    { title: "Facebook", name: "ceosocial1" },
    { title: "Twitter", name: "ceosocial2" },
    { title: "Linkedin", name: "ceosocial3" },
    { title: "Youtube", name: "ceosocial4" },
    { title: "Wikipedia", name: "ceosocial5" },
  ];
  const formRef = useRef();
  // const [fetched, setFetched] = useState(false);
  // const { notify } = props;
  //   console.log(id);
  useEffect(() => {
    if (props.id) {
      const getRegister = () => {
        aictia
          .get(`ReyestrOfCompany/${props.id}`)
          .then((res) => {
            setPhoneNum(res.data.phoneOfCompany.length);
            setEmail(res.data.officialMail.length);
            setCeoPhoneNum(res.data.phoneOfCeo.length);
            setCeoEmail(res.data.mailOfCeo.length);
            setText({ note: res.data.note });
            res.data.socialNetworkofCompany.map((l) => {
            
              form.setFieldsValue({ [`social${l.type}`]: l.value });
            });
            res.data.socialNetworkofCeo.map((l) => {
            
              form.setFieldsValue({ [`ceosocial${l.type}`]: l.value });
            });
            form.setFieldsValue({ [`type`]: res.data.phoneOfCompany });

            form.setFieldsValue({
              ...res.data,
              dateOfIncorporationCompany: moment(
                res.data.dateOfIncorporationCompany
              ),
            });
            setData(res.data);
          })
          .catch((err) => {
            props.notify("Xəta baş verdi", false);
          });
      };
      getRegister();
   

      // formRef.current.setFieldsValue(data);
    }
    const getCountry = () => {
      aictia.get("Country").then((res) => {
        setCountry(res.data);
      });
    };
    const getStateOrPrivate = () => {
      aictia.get("StateorPrivate").then((res) => { 
        setState(res.data);
      });
    };
    const getTypeOfActivity = () => {
      aictia.get("TypeOfActivity").then((res) => {
        setTypeOfActivity(res.data);
      });
    };
    const getActivity = () => {
      aictia.get("Activity").then((res) => {
        setActivity(res.data);
      });
    };
    const getAictiaPacket = () => {
      aictia.get("PacketOfAictia").then((res) => {
        setPacket(res.data);
      });
    };
    getStateOrPrivate();
    getCountry();
    getTypeOfActivity();
    getActivity();
    getAictiaPacket();
    //form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.worker, t]);
  
  // useEffect(() => {
  //   if (props.visibleEditWorker && !props.worker) {
  //     form.resetFields();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.visibleEditWorker]);

  const saveItem = (values) => {
    const media = [
      {
        type: 1,
        socialmeda: "facebook",
      },
      {
        type: 2,
        socialmeda: "twitter",
      },
      {
        type: 3,
        socialmeda: "linkedin",
      },
      {
        type: 4,
        socialmeda: "youtube",
      },
      {
        type: 5,
        socialmeda: "wikipedia",
      },
    ];
    const data = {
      ...values,
      authorizedCapital: String(values.authorizedCapital),
      DateOfIncorporationCompany: values.dateOfIncorporationCompany
        ? moment(values.dateOfIncorporationCompany).format("YYYY-MM-DD")
        : null,
      SocialNetworkofCompany: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`social${l.type}`],
        };
      }),
      SocialNetworkofCeo: media.map((l) => {
        return {
          type: String(l.type),
          value: values[`ceosocial${l.type}`],
        };
      }),
      TypeOfCompany: Number(values.typeOfCompany),
    };
    if (props.id) {
      aictia
        .put(`ReyestrOfCompany/${props.id}`, { ...data, id: props.id })
        .then((res) => {
          form.resetFields();

          props.notify("Deyisdirildi ", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify("Xəta baş verdi", false);
        });
    } else {
      aictia
        .post(`ReyestrOfCompany`, data)
        .then((res) => {
          form.resetFields();

          props.notify("Əlavə olundu", true);
          window.history.back();
        })
        .catch((err) => {
          props.notify(err, false);
        });
    }

  };
  return (
    <div>
      <Form onFinish={saveItem} layout="vertical" form={form}>
        <div className="commontask">
          <Row gutter={[16, 16]}>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Ölkə"}
                name="countryId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {country.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Sahibkarlıq subyektinin adı(SSA)")}
                name="nameOfCompany"
                validateTrigger="onChange"
                rules={[whiteSpace(t("inputError"))]}
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
       
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Sahibkarlıq subyekti"}
                name="typeOfCompany"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {items.map((p, index) => {
                    return (
                      <Option key={index} value={p.item}>
                        {p.code}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Faktiki ünvan")}
                name="actualAddress"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Hüquqi ünvan")}
                name="legalAddress"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("İşçi sayı")}
                name="workerCount"
                validateTrigger="onChange"
              >
                <InputNumber size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Qeydiyyat Tarixi")}
                name="dateOfIncorporationCompany"
                validateTrigger="onChange"
              >
                <DatePicker placeholder={t("")} className="w-100" />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label="Subyektin Rəsmi Təmsilçi"
                name="officialCEOoftheCompany"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Satış Nişanı")}
                name="saleSign"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Vöen")}
                name="tin"
                validateTrigger="onChange"
                rules={[
                  whiteSpace(t("inputError")),
                  {
                    max: 10,
                    message: "10 simvol olmalıdır",
                  },
                  {
                    min: 10,
                    message: "10 simvol olmalıdır",
                  },
                  {
                    pattern: /^-?\d+(\.\d+)?$/,
                    message: "Sadəcə rəqəm olmalıdır",
                  },
                ]}
              >
                <Input disabled={props.id ? true : false} size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Dövlət/Özəl"}
                name="stateorPrivateId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {state.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Fəaliyyət Növü (F/N)"}
                name="typeOfActivityId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {typeOfActivity.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Fəaliyyət sahəsi"}
                name="activityId"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Select>
                  {activity.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Rəsmi Web səhifəsi")}
                name="officialWebSite"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="form-lang">
                <Form.Item
                  label={t("Nizamnamə kapitalı")}
                  name="authorizedCapital"
                  validateTrigger="onChange"
                >
                  <InputNumber />
                </Form.Item>
                <div className="input-lang">azn</div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={t("Tanınan Rəhbər")}
                name="recognizedCeo"
                validateTrigger="onChange"
              >
                <Input size={"large"} />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label={"Üzvlük paketi"}
                name="packetOfAictiaId"
                validateTrigger="onChange"
              >
                <Select>
                  {packet.map((p, index) => {
                    return (
                      <Option key={index} value={p.id}>
                        {p.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Rəhbərin telefon nömrəsi</span>
                </div>
                {[...Array(ceoPhoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCeo", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input placeholder={"+994XXXXXXXXXX"} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoPhoneNum(ceoPhoneNum + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() =>
                      ceoPhoneNum > 1 && setCeoPhoneNum(ceoPhoneNum - 1)
                    }
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Ceo maili</span>
                </div>
                {[...Array(ceoEmail)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["mailOfCeo", index, "value"]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setCeoEmail(ceoEmail + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => ceoEmail > 1 && setCeoEmail(ceoEmail - 1)}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Şirkətin Telefon nömrəsi</span>
                </div>
                {[...Array(phoneNum)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["phoneOfCompany", index, "value"]}
                      rules={[
                        {
                          pattern: /^[^a-zA-Z]*$/,
                          message: "Hərflər olmamalıdır!",
                        }
                      ]}
                    >
                      <Input placeholder={"+994XXXXXXXXXX"} />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setPhoneNum(phoneNum + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => phoneNum > 1 && setPhoneNum(phoneNum - 1)}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Rəsmi mail</span>
                </div>
                {[...Array(email)].map((_, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={["officialMail", index, "value"]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                ))}

                <div>
                  <Button
                    onClick={() => setEmail(email + 1)}
                    className={"mr-10"}
                    type={"primary"}
                    shape={"circle"}
                    icon={<PlusOutlined />}
                  />
                  <Button
                    onClick={() => email > 1 && setEmail(email - 1)}
                    type={"primary"}
                    shape={"circle"}
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Şirkətin sosial mediaları</span>
                </div>
                {socialMedia.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <div className="p-2 border">
                <div className="flex mb-15  flex-align-center flex-between">
                  <span>Rəhbərin sosial mediaları</span>
                </div>
                {socialMediaCeo.map((elem, index) => (
                  <div className="mb-10 " key={index}>
                    <Form.Item
                      className={"mb-0 w-100 mr-10"}
                      validateTrigger="onChange"
                      name={elem.name}
                      label={elem.title}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                ))}
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label={t("Qeyd")}
                name="note"
                validateTrigger="onChange"
              >
                <JoditEditor
                  onChange={(newContent) => {
                    form.setFieldsValue({
                      [`note`]: newContent,
                    });
                  }}
                  className={"mb-15"}
                  ref={editor}
                  value={text["note"]}
                  tabIndex={1} // tabIndex of textarea
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "40px" }}
        >
          <Link to={{ pathname: "/register" }}>
            <Button>{t("cancel")}</Button>
          </Link>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default connect(null, { notify })(Company);
